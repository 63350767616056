.custom-table-container {
    margin: 10px;
    overflow-x: auto; /* Enables horizontal scrolling */
    overflow-y: auto; /* Enables vertical scrolling */
    max-height: 80vh; /* Set the maximum height for the container */
  }
  
  .custom-table-container table {
    table-layout: fixed; /* Keeps the column widths as defined */
    border-collapse: collapse;
    width: 100%; /* Set to 100% or a fixed width as needed */
    margin-top: 10px;
  }
  
  .custom-table-container th, .custom-table-container td {
    width: 320px; /* Fixed width for each cell */
    height: 50px; /* Fixed height for each cell */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Ensures text stays in a single line */
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .custom-table-container thead {
    background-color: #f0f0f0;
  }
  
  .row-of-containers {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
  
  .row-of-containers > div {
    padding: 10px;
    border: 1px solid #ddd;
    /* Additional styling for the containers */
  }

  .custom-table-container td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    height: 50px; /* Fixed height for each cell */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Ensures text stays in a single line */
  }